html,body{
  overflow-x: hidden;
  
}
.shop {
  background-color: rgb(128,128,128);
  width: 100%;
  height: auto;
  padding-top: 5%;
  padding-bottom: 5%;
}


.shop-items {
  display: flex;
  flex-direction: row;
  flex-flow: row wrap;
  align-content: flex-start;
  border: 3px solid black;
  width: auto;
  height: auto;
  padding-top: 8%;
  
  
}

.shop-item {
  display: flex;
  flex-direction: column;
  
  /*margin: 5%;*/
}

.shop-item-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 2rem;
  text-align: center;
  margin-top: -10%;
  font-weight: 900;
}

.shop-item-price {
  font-family: 'Open Sans', sans-serif;
  color: #0d0106;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
}

.shop-item img {
  margin-right: auto;
  margin-left: auto;
}

.shop-item form {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.shop-item table {
  margin-left: auto;
  margin-right: auto;
}
.shop-item select {
  width: 100%;
  font-size: 1em;
  padding: 0.5em;
  margin-top: 0.5em;
  text-align: center;
  margin-bottom: 1rem;

}


.shop-item-details p {
  text-align: center;
  
}

.details {
  line-height: 10px;
  font-weight: bold;
}

.three, .four {
  margin-top: 7rem;
  
}

@media only screen and (max-width: 1024px) {
  .shop-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
  }

  
 
  
}
@media only screen and (max-width: 768px) {
  .shop-items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 50px;
  }

  .one, .two, .three, .four {
    margin-top: 50px;
  }
  
 
  
}

@media only screen and (max-width: 425px) {
  .one, .two, .three, .four {
    margin-top: 40px;
  }

  .zero {
    margin-top: 20px;
  }
 
 
}


.price-body {
  height: 100%;
  padding-top: 40px;
}
.price-info {
  text-align: center;
  width: 60%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  font-weight: 900;
  font-family: 'Open Sans', sans-serif;
  color: #000000;
  display: flex;
  flex-direction: column;
  line-height: normal;
  border: 3px solid #000000;
  padding-top: 2rem;
}


.price-bottom {
  color: #0000FF;
}

.price-info h3 {
  text-align: left;
  margin-left: 100px;
  font-weight: 900;
}

.price-info h2 {
  font-weight: 900;
}

.price-info h4 {
  text-align: left;
  margin-left: 150px;
  font-weight: 900;
}

.price-info h5 {
  font-weight: 900;
  color: #0000FF;
  font-size: 1.5rem;
  width: 75%;
  margin-right: auto;
  margin-left: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  
}



@media only screen and (max-width: 1024px) {
  .price-body {
    height: 100%;
  }
  .price h2 {
    font-size: 2.5rem;
  }
  .price-body {
    margin-top: -5px;
  }
  .price-info h3 {
    text-align: left;
    margin-left: 50px;
  }

  .price-info h4 {
    text-align: left;
    margin-left: 50px;
  }

  .price-info {
    width: 90%;
    margin-top: 50px;
    font-size: 1.5rem;
  }

  .price-info h5 {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 375px) {
  .price-bottom {
    width: 100%;
  }
  .price h2 {
    font-size: 2.5rem;
  }
  .price-info {
    width: 90%;
    
    font-size: 1rem;
    padding: 1rem;
  }
  .price-info h3 {
    text-align: left;
    margin-left: 10px;
  }
.price-info p {
  text-align: center;
}
  .price-info h4 {
    text-align: left;
    margin-left: 10px;
    font-size: 1.25rem;
  }

  .price-info h5 {
    font-size: 1rem;
    width: 100%;
  }
}

.faq-container {
  margin-top: -100px;
  padding-top: 150px;
}
.FAQ {
  width: 75%;
  font-family: 'Open Sans', sans-serif;
  color: #ffffff;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
  height: 100%;
  border: 3px solid black;
}

.FAQ h1 {
  text-align: center;
  color: #ffffff;
  font-size: 3rem;
  font-weight: 900;
  padding-top: 1rem;
}

.FAQ h3 {
  text-align: center;
  color: #0000ff;
  margin-top: 2rem;
  font-weight: 900;
}

.FAQ p {
  text-align: center;
  margin-top: 3rem;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  font-weight: bold;
  font-weight: 900;
}

@media only screen and (max-width: 1024px) {
  .FAQ {
    margin-top: 30px;
    width: 90%;
  }

  .FAQ h1 {
    font-size: 2.5rem;
  }
  .FAQ h3 {
    font-size: 1.75rem;
  }

  .FAQ p {
    font-size: 1.25rem;
  }

  .FAQ p {
    width: 90%;
  }
}


@media only screen and (max-width: 375px) {
  .FAQ {
    width: 95%;
  }
  .FAQ h1 {
    font-size: 2rem;
  }
  .FAQ h3 {
    font-size: 1.5rem;
  }

  .FAQ p {
    font-size: 1rem;
  }
}

body {
  background-color: #ffffff;
}
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  border: 3px solid #000000;
  width: 75%;
  margin-right: auto;
  margin-left: auto;
  height: 100%;
  margin-top: 45px;
}

img {
  width: 350px;
  
}
.about-me {
  
  font-family: 'Open Sans', sans-serif;
  color: black;
  font-size: 2rem;
  text-align: center;
  font-weight: bold;
  
  width: 90%;
  height: auto;
}

.about-me h1 {
  font-weight: bold;
}
.about-me p {
  color: #000000;
  opacity: 50%;
}

@media only screen and (max-width: 1024px) {
  .about {
    height: 100vh;
    margin-top: 100px;
  }

  .about-me {
    margin-top: 50px;
    font-size: 1.75rem;
  }

  .about-me h1 {
    font-size: 2rem;
  }
}

@media only screen and (max-width:768px) {
  .about {
    height: 100vh;
    margin-top: 75px;
  }
  .about-me {
    margin-top: 25px;
    font-size: 1.5rem;
  }
}
@media only screen and (max-width: 40em) {
  .about {
    height: auto;
    padding-bottom: 20px;
  }
  img {
    width: 200px;
    
  }
  .about-image {
    
    margin-bottom: 5%;
    
  }
  .about-me h1 {
    font-size: 1.75rem;
  }
  .about-me {
    font-size: 1rem;
    width: 80%;
  }
}

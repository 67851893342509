body {
  background-color: black;
  margin: 0;
  padding: 0;
}
.background-image {
  background-color: black;
  height: 120vh;
  
  
}
.center-potrait {
  position: relative;
  margin-right: auto;
  margin-left: auto;
  left: 50%;
  display: none;
}
.center {
  position: absolute;
  width: 88%;
  top: 13vh;
  display: block;
  left: 6%;
  
  
}

.background-button {
  margin-top: 500px;
  margin-right: 550px;
}
.background-button button {
  text-decoration: none;
  color: white; 
  background-color: #0000ff;
   width: 20%;
   height: 10%;
   box-shadow: 1px 1px 1px grey;
   font-family: Oswald;
   font-size: 1.5rem;
   border: none;
}

.request-quote {
  margin-right: 3rem;
}



@media only screen and (max-width: 1024px) {
 
 .center {
  width: 90%;
  top: 12vh;
 }

 .background-button {
  margin-top:400px;
  margin-right: 450px;
}
 
}
@media only screen and (max-width: 912px) {
.center-potrait {
  display: block;
  width: 90%;
  left: 0%;
  padding-top: 10vh;
}

.center {
  display: none;
}
  .background-button {
    margin-top: 750px;
    margin-right: 0px;
  }

  .background-button button {
    width: 25%;
  }
}

@media only screen and (max-width: 820px) {
  .background-button {
    margin-top: 650px;
  }
}

@media only screen and (max-width: 768px) {
  .background-image {
    background-color: black;
    height: 110vh;
  }
  
   .background-button {
    padding: 1.5rem; 
    margin-top: 600px;
   }

 

  .center-potrait {
    width: 85%;
  }

}


@media only screen and (max-width: 540px) {

.background-button {
  margin-top: 425px;
}


 .background-button button {
  padding: 0rem;
  width: 25%;
  height: 15%;
  font-size: 1.5rem;
  
 }

 .request-quote {
  margin-right: 1rem;
  font-size: 1.5rem;
}

.background-image {
  height: 100vh;
}

}

@media only screen and (max-width: 414px) {
  .background-button {
    margin-top: 450px; 
  }

.background-button button {
  padding: -1rem;
}

.center-potrait {
  
  width: 90%;
  height: 80%;
}
}

  @media only screen and (max-width: 375px) {
    .background-button {
      margin-top: 350px; 
    }
  }

  @media only screen and (max-width: 360px) {
    .background-button {
      margin-top: 400px;
    }
  }
  @media only screen and (max-width: 320px) {
.background-button {
  margin-top: 350px;
}
.background-button button {
  width: 35%;
}
  }